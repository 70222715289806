import React, { FC, useState } from 'react';
import { ProductResponseType } from '../../../models/payment';
import { Button, Card, Statistic } from 'antd';
import styled from 'styled-components';
import { createCheckoutSession } from '../../../client';

interface Props {
  data: ProductResponseType;
}

const StyledCard = styled(Card).attrs({ hoverable: true })`
  width: 260px;
  cursor: pointer;
  display: inline-block;
  flex: 0 0 auto;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 24px 0;
`;
const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
`;
const ExpirationWrapper = styled.div`
  font-size: 22px;
  margin: 20px auto 32px;
`;
const Title = styled.div`
  font-size: 32px;
  font-weight: bolder;
  margin: 20px 0;
`;
const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  border-radius: 14px;
`;
const ButtonText = styled.div`
  font-size: 22px;
`;
export const ProductCard: FC<Props> = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const handleClick = async () => {
    if (!loading) {
      setLoading(true);
      const redirectUrl = await createCheckoutSession(
        data.id,
        window.location.href
      );
      redirectUrl && window.location.assign(redirectUrl);
    }
  };
  return (
    <StyledCard onClick={handleClick}>
      <ContentWrapper>
        <Title>{data.tokenCount} Letters</Title>
        <PriceWrapper>
          $
          <Statistic
            value={data.priceInCent / 100}
            valueStyle={{ fontSize: '24px' }}
          />
        </PriceWrapper>
        <ExpirationWrapper>
          expires in {data.effectDuration} days
        </ExpirationWrapper>
        <StyledButton type={'primary'} loading={loading}>
          <ButtonText>Top up</ButtonText>
        </StyledButton>
      </ContentWrapper>
    </StyledCard>
  );
};
